#api-docs #guide .logo {
  width: auto !important;
  height: auto !important;
  margin-left:auto;
  margin-right:auto;

  .wave-logo{
    height:65px;
  }
}

.index-clients {
  margin-top: 0px !important;
  margin-bottom: 100px;  

  opacity: 0.85;
}

.index-app-news{
  border-bottom: none !important;

}
.index-api-features{
  //border-top: 1px solid #e4e4e4;
  position: relative;
  padding-top: $block-space-top-small; 
  padding-bottom: $block-space-bottom-large+30px;

  .row{
    margin-top:18px;
  }

  .actions {
    padding-top: 32px;
  }

  .actions a {
    margin-top:20px;
    margin-right:20px;
    background: #459ce7;
    color: #fff;
    padding: 13px 30px;
    border-radius: 50px;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-decoration: none;
  }

  .header{

  h3 {
    font-size: 32px;
    color: #4b4b4b;
    line-height: 38px;
    font-weight: 400;
    margin-top: 16px;
    margin-bottom: 32px;
    text-align: center;
  }
}

  .feature {

    .feature-header{
      height:90px;

    img{
      height:80px;

      &.hor{
        height: 60px;
      }
    }

    h3 {
      color: #2f4166;
      font-size: 18px;
      //margin-top: 20px;
      text-align: left;
      display: inline-block;
      vertical-align: bottom;
      margin-left: 1.5rem;
    }



    }
    margin-top: 50px;
    width: 90%;
    padding-left:20px;
    padding-right:20px;

    p {
      margin-top: 30px;
      line-height: 1.7em;
      font-size: 18px;
      color: #7b8398;
    }
  }
}

.index-app-features {
  //border-top: $border;
  position: relative;
  padding-top: $block-space-top-large; 
  padding-bottom: $block-space-bottom-large;

  @media (max-width: 767px) { 
    padding-top: $block-space-top-small; 
    //padding-bottom: $block-space-bottom-small;
  }

  &.first{
    h4, p, a {
      color:white !important;
    }
    background-image: linear-gradient(#319BED 0, #168EEA 75%, #fff 55%);
    @media (max-width: 767px) { 
      background-image: linear-gradient(#319BED 0, #168EEA 80%, #fff 55%);
    }
  }

  &.last{
    background: #168EEA top left;
    h4, p, a {
      color:white !important;
    }

    section {
      min-height: 500px;
    }
  }

  section {

    .info{
      width: auto;
      max-width: $section-width;
      text-align: center;

      img {
        width: 164px;

        @media (max-width: 767px) { 
          width: 120px;
        }
      }

      a {
        font-weight: 600;

      }

      &.callout {
        margin-top: 100px;
        float: left;
        max-width: 45%;

        @media (max-width: 767px) { 
          margin-top: 0px;
          max-width: 100%; 
          float:none;
        }

        h4{
          font-weight: 400;

        }

        a{
          font-weight: 600;

        }

        h4, p, a{
          text-align: left;
          width:auto;

          @media (max-width: 767px) { 
            text-align: center;
            margin-left:auto;
            margin-right:auto;
            width: 80%;
          }
        }

        .callout-headline{
          display:flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          img {
            width: 50px;
            margin-right: 25px;

            @media (max-width: 767px) { 
              width: 40px;
              margin-right: 20px;
            }
          }
        }
      }

    }

    .info-callout-image{
      float:right;
      width: 50%;
      margin-bottom: 50px;

      img {
        position: absolute;

        &:first-child {
          width: 450px;
        }

        &:last-child {
          margin-top:200px;
          right:-100px;
        }

        @media (max-width: 767px) { 
          position: relative;
          margin-top:20px;

          &:first-child {
            width: 411px;
          }

          &:last-child {
            margin-top:20px;
            right: 0px;
          }
        }
      }


      @media (max-width: 767px) { 
        float:none;
        width: auto;
        margin-bottom:0px;
      }
    }

    .info-image {
      text-align: center;
      display: block;

      img {
        margin-bottom: $app-img-margin-bottom-large;
        margin-top: $app-img-margin-top-large;
        height: auto;
        margin-left:auto;
        margin-right:auto;

        @media (max-width: 767px) { 
          margin-bottom: $app-img-margin-bottom-small;
          margin-top: $app-img-margin-top-small;
          max-width: $app-img-max-width;
        }
      }

      &.screen img{
        box-shadow: $screenshot-img-shadow;
        max-width: unset;

        @media (max-width: 767px) { 
          max-width: 120%;
          //margin-bottom: $app-img-margin-bottom-small + 10;
          //margin-top: $app-img-margin-top-small + 10;
        }
      }
    }

    h4 {
      @media (max-width: 767px) { 
        font-size: $header-small;
        text-align: $text-align-small;
      }
    }

    p {
      line-height: $p-line-height;
      font-size: $p-font-size;
      width: 80%;
      margin-left:auto;
      margin-right:auto;
      text-align: center;

      @media (max-width: 767px) { 
        font-size: $p-small;
        width: auto;
        margin-top: $p-margin-top-small;
      }

      .icon{
        padding-left: 1em;
        font-size: 0.8em;
      }
    }
  }
}


.app-cta{
  margin-top: $block-space-top-large;
  margin-bottom: $block-space-bottom-large; 

  @media (max-width: 767px){
    margin-top: $block-space-top-small; 
    margin-bottom: $block-space-bottom-small; 

    .cta p {
      text-align: center;
    }

    .typed-text{
      padding-top: 0px;
      margin-top: 0px;
      height: auto;
    }
  }

}

.index-features-files {
  padding-top: 0px;
  margin-top: 0px !important;

  .container1{
    display: flex;
    padding-top: 30px;
    padding-bottom: 30px;
    @media (max-width: 767px) {
      flex-direction: column-reverse;
    }
  }

  .col {
    @media (max-width: 767px) {
      flex-basis: auto !important;
    }
  }

  .col-left{

    .features .info{
      padding:0px;
      margin:0px;
      width: 90%;
    }

    @media (max-width: 767px) {
      padding:0px;
      margin:0px;

      .features {
        padding:15px;
        padding-bottom:0px;

        .info{
          text-align: center;
          margin-left:auto; //align middle
          margin-right:auto; //align middle

          p {
            margin-left:auto; //align middle
            margin-right:auto; //align middle
          }
        }

        .feature img{
          display: block;
          width: 40px;
          margin-left:auto; //align middle
          margin-right:auto; //align middle
        }
      }
    }
  }

  .col-right{
    h3 {
      font-size: 18px;
    }

    p {
      line-height: $p-line-height; 
      font-size: $p-font-size;


      a {
        font-weight: 600;

      }

      .icon{
        padding-left: 1em;
        font-size: 0.8em;
      }
    }

    @media (max-width: 767px) {
      padding-bottom: 0px;

      .info{
        padding:15px;
        padding-top:0px;
        text-align:center;
      }

      h3 {
        text-align: $text-align-small;
        margin-left:auto; //align middle
        margin-right:auto; //align middle
      }

      h4 {
        font-size: $header-small;
        margin-left:auto; //align middle
        margin-right:auto; //align middle
      }

      p{
        margin-top: $p-margin-top-small;
      }
    }
  }
}

.pricing-plans .wrapper {
  top: 0px;
  margin-bottom:0px

}

.pricing-plans, .pricing-charts-faqs {
  color: #212529
}

.pricing-charts-tabs {
  .zendesk {
    border-radius: 45px 0 0 45px;
  }
  .wordpress {
    border-left: 0;
    border-radius: 0 45px 45px 0;
  }
  margin-bottom: 25px;
}

.pricing-charts .chart {
  >header {
    height: 205px;

    img {
      margin-left: auto;
      display: block;
      margin-right: auto;
      margin-bottom:10px
    }

    .price {

      transition: unset;

      &.zendesk {
        transform: translateY(25px);

        &.active{
          opacity: 1;
          transform: translateY(0px);
        }
      }

      &.wordpress{
        transform: translateY(-25px);

        &.active{
          opacity: 1;
          transform: translateY(0px);
        }
      }

      .currency {
        top: -10px;
      }

      .qty  {
        font-size: 50px;
      }
    }
  }
  .features {
    min-height:260px;
    &.zendesk {
      display:none;

      &.active{
        display:block;
      }
    }

    &.wordpress{
      display:none;

      &.active{
        display:block;
      }
    }
    .feature{
      //font-size: 20px;
    }
  }

}
.index-mobile-footer:before {
  background-image: linear-gradient(#319BED 0, #168EEA 75%, #fff 55%);
}
